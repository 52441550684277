import React from "react";

const formatPrice = (price) => (Math.round(price * 100) / 100).toFixed(2);

const CPrice = (props) => {
   var { price, oldPrice, currency } = props;
   var currencyString = currency === 'EUR' ? '€' : currency;
   if (!window.isSet(price) || !window.isSet(currency)) {
     console.warn(`CPrice : missing price or currency`);
     return null;
   }
   return (<>
    { window.isSet(oldPrice) && price !== oldPrice ? (<s>{ formatPrice(oldPrice)}</s>):null}
    &#160;<span className="select-all-text">{ formatPrice(price)} {currencyString}</span>
  </>);
}

export default CPrice;