import React, {Component} from 'react';
import './CList.css';
import CUtils from '../services/CUtils'
import ApiV0 from '../services/ApiV0'
import MetaTags from 'react-meta-tags';
import CListingChoice from './CListingChoice';
import {Alert} from 'react-bootstrap';
import {analyticsPageView, analyticsEvent} from '../core/AnalyticsManager';
import {CConstants} from '../common/CConstants';
import CBreadcrumb from '../common/CBreadcrumb';
import CLoadInProgress from '../common/CLoadInProgress';
import {DiCode} from 'react-icons/di';
import {withTranslation} from 'react-i18next';

const DEBUG_CLIST = false;

class CList extends Component {
    state = {
        section: null,
        errorMessage: null,
        limit: 10,
        bookmark: null,
        listings: null,
        searchTodo: true,
        wip: true
    }

    constructor(props) {
        super(props);

        this.listInnerRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("scroll", this.onScroll);
        this.getSection();
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.onScroll);
    }

    _refocus() {
        DEBUG_CLIST && console.log("_refocus");
        if (!this.state.section || !this.state.listings || this.state.pv === true) {
            return;
        }
        const {t} = this.props;
        this.setState({pv: true, wip: false}, () => {
            document.title = t('list.title', {section: this.getSectionName()});
            analyticsPageView('list ' + (this.state.section ? this.getSectionName() : ""));
        });
    }

    getSection() {
        DEBUG_CLIST && console.log("getSection");
        const {t} = this.props;
        ApiV0.getSection(this.props.sectionId)
            .then((sectionResult) => {
                this.setState({section: sectionResult, errorMessage: null, infoMessage: null, wip: true},
                    () => this.searchListings());
            })
            .catch(apiError => {
                const errorMessage = CUtils.userErrorOf(t('list.error.sectionError'), t, apiError);
                this.setState({errorMessage, wip: false}, () => this._refocus());
            });
    }

    searchListings() {
        DEBUG_CLIST && console.log("searchListings");
        const {t} = this.props;
        const returnTo = window.location.href;
        const limit = this.state.limit;
        const bookmark = this.state.bookmark;
        let filter = {limit, returnTo};
        if (bookmark && bookmark !== '') {
            filter.bookmark = bookmark;
        }
        if (this.state.section) {
            filter.section = this.state.section.shop_section_id;
        } else if (this.props.sectionId) {
            filter.section = this.props.sectionId;
        }
        const nbListings = this.state.listings ? this.state.listings.length : 0;
        const evt = 'list ' + (this.state.section ? this.getSectionName() : "") + " getListings";
        analyticsEvent(CConstants.GG_CATEGORY.LISTING, evt, evt, nbListings);
        // DEBUG // console.info("searchListings", filter);
        ApiV0.getListings(filter)
            .then((listingsResults) => {
                if (this.isSet(listingsResults)) {
                    const newListing = this.state.listings === null ? listingsResults : this.state.listings.concat(listingsResults);
                    this.setState({
                            listings: newListing,
                            errorMessage: null,
                            infoMessage: null,
                            hasNext: true,
                            searchTodo: false
                        },
                        () => this._refocus());
                } else {
                    const newState = {hasNext: false};
                    if (bookmark == null || bookmark === '') {
                        newState.listings = null;
                    }
                    this.setState(newState, () => this._refocus())
                }
            })
            .catch(apiError => {
                const errorMessage = CUtils.userErrorOf(t('list.error.searchError'), t, apiError);
                this.setState({errorMessage, searchTodo: false});
            });
    }

    onNext() {
        const cList = this;
        if (this.state.searchTodo === false && this.state.listings && this.state.listings.length > 0) {
            const lastCreation_tsz = this.state.listings[this.state.listings.length - 1].creation_tsz;
            this.setState({bookmark: lastCreation_tsz, searchTodo: true}, () => cList.searchListings());
        }
    }

    // https://stackoverflow.com/questions/45585542/detecting-when-user-scrolls-to-bottom-of-div-with-react-js
    isBottom(el) {
        return el.getBoundingClientRect().bottom <= (el.getBoundingClientRect().height - 200);
    }

    getSectionName() {
        const lang = ApiV0.getLang();
        return CUtils.getSectionTitle(this.state.section, lang);
    }

    hasReachedBottom(margin) {
        return ((window.innerHeight + window.scrollY + margin) >= document.body.offsetHeight);
    }

    onScroll = () => {
        if (this.hasReachedBottom(100)) {
            // DEBUG // console.info("you're at the bottom of the page");
            this.onNext();
        }
        // DEBUG // console.info("window.innerHeight", window.innerHeight, " window.scrollY", window.scrollY, "document.body.offsetHeight", document.body.offsetHeight);
    };

    redirectHome(timeoutMs = 100) {
        setTimeout(() => {
            window.location.href = '/';
        }, timeoutMs)
    }


    render() {
        const errorPlace = this.state.errorMessage ? (
            <Alert variant="warning">{this.state.errorMessage}</Alert>) : null;
        if (this.state.wip === true || this.state.listings === null) {
            return this.state.errorMessage ?
                (<div id="listingsList" className="clist">
                    <div id="listingsInnerList" className="list-inner">{errorPlace}</div>
                </div>)
                : (<CLoadInProgress/>);
        }
        const {t} = this.props;
        const sectionName = this.getSectionName();
        const title = t('list.title', {section: sectionName});
        const sectionFullUrl = "https://www.creharmony.fr/listing/" + this.props.sectionId;
        const firstListing = this.state.listings && this.state.listings.length > 0 ? this.state.listings[0] : null;
        const mainImage = firstListing && firstListing.mainImage ? firstListing.mainImage : firstListing && firstListing.images ? firstListing.images[0] : null;
        const mainImageSrc = mainImage && mainImage['url_fullxfull'] ? mainImage['url_fullxfull'] : null;
        const tagsComma = firstListing ? firstListing.tags.join(",") : "";

        const metaTags = (<MetaTags>
            <link rel="canonical" href={sectionFullUrl}/>
            <meta id="og-title" property="og:title" content={title}/>
            <meta id="og-image" property="og:image" content={mainImageSrc}/>
            <meta id="meta-description" name="description" content={title}/>
            <meta id="meta-keywords" name="keywords" content={tagsComma}/>
        </MetaTags>);

        if (this.state.listings.length === 0) {
            this.redirectHome(5000);
        }
        const breadcrumb = (<CBreadcrumb activeLabel={sectionName}/>);
        return (
            <div id="listingsList" className="clist"
                 itemScope
                 itemType="https://schema.org/ItemList"
                 name={title}
            >
                {metaTags}
                <span className="hidden" itemProp="name">{title}</span>
                {breadcrumb}
                <div id="listingsInnerList" className="list-inner" onScroll={this.onScroll.bind(this)}
                     ref={this.listInnerRef}>
                    {errorPlace}
                    <div className="listItems alignCenter">
                        {this.state.listings.length === 0 ? (
                            <div className="noListing">{t('list.none')}</div>) : (null)}
                        {this.state.listings
                            .filter(p => p.images && p.images.length > 0)
                            .map((product, index) => {
                                return (
                                    <CListingChoice key={product.listing_id} index={product.listing_id}
                                                    section={this.props.sectionId}
                                                    product={product}
                                    />)
                            })}
                    </div>
                </div>
                <div className="endOfList"><DiCode/></div>
            </div>
        );
    }

    isSet(variable) {
        return (variable !== undefined && variable !== null);
    }

}

export default withTranslation('common')(CList);