import i18next from "i18next";
import detector from "i18next-browser-languagedetector";

import common_fr from "./translations/fr/common.json";
import common_en from "./translations/en/common.json";

import { CConstants } from './common/CConstants';
import ApiV0 from './services/ApiV0';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const DEFAULT_LOCALE = 'fr';
const ACCEPTED_LOCALES = ['fr','en'];
const queryParams = new URLSearchParams(window.location.search);
const queryLang = queryParams.get('lang');
const cookieLang = getOrElse(CConstants.COOKIE_LANG_KEY, 'fr');
const lng = ACCEPTED_LOCALES.includes(queryLang) ? queryLang : cookieLang;
// DEBUG // console.log("DEFAULT UI-LANG ", lng, "cookies", cookieLang, "query", queryLang);
ApiV0.setLang(lng);

i18next
  .use(detector)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng, // language to use
    resources: {
        fr: { common: common_fr },
        en: { common: common_en },
    },
    fallbackLng: DEFAULT_LOCALE, // use fr if detected lng is not available
});


function getOrElse(cookieName, elseValue) {
  return cookies.get(cookieName) !== undefined ? cookies.get(cookieName) : elseValue;
}

export default i18next;