import React, { Component } from 'react';
import './CSearchBar.css';
import { BiSearchAlt2 } from 'react-icons/bi';

import queryString from 'query-string';
import { withTranslation } from 'react-i18next';

const currentPath = window.location.pathname;

class CSearchBar extends Component {

  constructor(props) {
      super(props);
      const query = window.location.search.substring(1);// url params
      const params = queryString.parse(query);
      const searchValue = params.q ? params.q : "";
      this.state = {
        q: searchValue
      };
  }

  componentDidMount() {
    document.body.addEventListener('ch-search', this.handleSearch.bind(this));
    this._refocus();
  }

  componentWillUnmount() {
    document.body.removeEventListener('ch-search', this.handleSearch.bind(this));
  }

  handleSearch(event) {
    const newSearchTerm = event.detail.q;
    if (newSearchTerm === "" && newSearchTerm !== this.state.q) {
      // handle only clear from child
      this.setState({q: newSearchTerm}, () => this._refocus());
    }
  }

  _refocus() {
    if (this.searchInput) {
      this.searchInput.focus();
    }
  }

  handleInputChange(event) {
      const target = event.target;
      const value = target.value;
      var name = target.name;
      // DEBUG // console.log(`${name}=${value}`);
      this.setState({
        [name]: value
      });
  }

  isSearchAllowed(searchString) {
    return (searchString !== undefined
         && searchString !== null
         && (searchString.length >= 3 || searchString === ""));
  }

  handleSubmit(event) {
    // const { t } = this.props;
    event.preventDefault();
    // analytics event is managed into result component that will ask listings results
    const q = this.state.q;
    if ( this.isSearchAllowed(q) ) {
      if (currentPath !== '/search') {
        document.location.href ='/search?'+queryString.stringify({q});
      } else {
        document.body.dispatchEvent(new CustomEvent("ch-search", {detail: { q }}));
      }
    }
  }

  render() {
    const { t } = this.props;
    const searchValue = this.state.q;
    const submitDisabled = !( this.isSearchAllowed(searchValue) );
    return (
      <div className="searchBar">
        <form className="cSearch" onSubmit={this.handleSubmit.bind(this)}>
          <input
            type="search"
            name="q"
            autoComplete="off"
            placeholder={t('searchbar.form.placeholder')}
            className="cSearchTerm"
            aria-label={t('searchbar.form.label')}
            onChange={this.handleInputChange.bind(this)}
            ref={(input) => { this.searchInput = input; }}
            value={searchValue}
          />
          <button className="cSearchButton" onClick={this.handleSubmit.bind(this)} disabled={submitDisabled}
                  title={t('searchbar.form.button')}><BiSearchAlt2/></button>
        </form>
      </div>
    );
  }

}

export default withTranslation('common')(CSearchBar);