import Cookies from 'universal-cookie';
import { CConstants } from '../common/CConstants';
import { decode } from 'html-entities';
import _ from 'lodash';

const CONSENT_COOKIES = 'consentCookies';
const CONSENT_STATS_COOKIES = 'consentStatsCookies';
const cookies = new Cookies();
const cookiesOptions = CConstants.COOKIE_DEFAULT_SET_OPTIONS;

class CUtils {

  static _(toDecode) {
    return decode(toDecode);
  }

  static maxString(value, maxLength = 50) {
    if (!window.isSet(value)) {
      return value;
    }
    if (value.length > maxLength) {
      return value.substring(0,maxLength-3) + "...";
    }
    return value;
  }

  static getSectionTitle(section, lang = 'fr') {
    if (!section) { return section; }
    return CUtils._(lang === 'en' && section.en_title ? section.en_title : section.title);
  }

  static getSectionLink(section, lang = 'fr') {
    return section && section.slug && lang === 'fr' ? `/listing/${section.slug}` :
           section && section.en_slug ? `/listing/${section.en_slug}` :
           section && section.shop_section_id ? `/listing/${section.shop_section_id}` :
               '/';
  }

  static generateShortTitle(decodedTitle, maxLength) {
    if (!window.isSet(decodedTitle)) {
      return decodedTitle;
    }
    var shortTitle = decodedTitle;
    if (decodedTitle.indexOf(' - ')>0) {
      shortTitle = decodedTitle.substring(0, decodedTitle.indexOf(' - '));
    }
    if (shortTitle.length > maxLength) {
      shortTitle = decodedTitle.substring(0,maxLength-3) + "...";
    }
    return shortTitle;
  }

  static generateProductLink(sectionId, listingId, decodedTitle, lang = null) {
     const result = CUtils.convertToSlug(CUtils.generateShortTitle(decodedTitle, 50));
     const langSelector = lang !== null ? `?lang=${lang}` : '';
     return `/listing/${sectionId}/${listingId}-${result}${langSelector}`;
  }

  static cleanDescription(rawDescription) {
    const description = rawDescription;
    return description ? description.split('\n').map((i,key) => {
        return <span key={key}>{i}<br/></span>
    }) : null;
  }

  static cleanMetaDescription(rawDescription) {
    const description = rawDescription;
    return description ? description.split('\n').join('') : null;
  }

  static getListingLink(listing, lang = 'fr') {
    if (!listing) { return listing; }
    const decodedTitle = CUtils._(lang === 'en' && listing.en_title ? listing.en_title : listing.title);
    return CUtils.generateProductLink(listing.shop_section_id, listing.listing_id, decodedTitle, lang);
  }

  static getListingShortTitle(listing, maxLength, lang = 'fr') {
    if (!listing) { return listing; }
    const decodedTitle = CUtils._(lang === 'en' && listing.en_title ? listing.en_title : listing.title);
    return CUtils.generateShortTitle(decodedTitle, maxLength);
  }

  static getListingDescription(listing, lang = 'fr') {
    const description = lang === 'en' && listing.en_description ? listing.en_description : listing.description
    return CUtils.cleanDescription(CUtils._(description));
  }

  static getListingMetaDescription(listing, lang = 'fr') {
    const description = lang === 'en' && listing.en_description ? listing.en_description : listing.description
    return CUtils.generateShortTitle(CUtils.cleanMetaDescription(CUtils._(description)), 200);
  }

  static getListingTags(listing, nb = 1) {
    const tagsArray = listing && listing.tags ? listing.tags.slice(0, Math.min(listing.tags.length, nb)) : [];
    return tagsArray.map( t => _.camelCase(removeAccent(t)) );
  }

  static getListingImage(listing) {
    return listing && notEmptyArray(listing.images) ? listing.images[0]["url_570xN"] : null;
  }

  static listingMainImageUrl(listing) {// same as Common
    return listing.mainImage && listing.mainImage["url_570xN"] ? listing.mainImage["url_570xN"] :
        listing.mainImage && listing.mainImage["url_fullxfull"] ? listing.mainImage["url_fullxfull"] :
            listing.images && listing.images[0] && listing.images[0]["url_570xN"] ? listing.images[0]["url_570xN"] :
                listing.images && listing.images[0] && listing.images[0]["url_fullxfull"] ? listing.images[0]["url_fullxfull"] :
                    null;
  }

  static getListingTagsComaSeparated(listing, lang = 'fr') {
    const tags = lang === 'en' && listing.en_tags ? listing.en_tags : listing.tags
    return tags ? tags.join(', ') : null;
  }

  static convertToSlug(Text) {
    var converted = Text.toLowerCase();
    if (converted.normalize) {
      converted = converted.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    return converted
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
        ;
  }

  static hasConsentCookie(defaultValue = false) {
    return cookies.get(CONSENT_COOKIES) !== undefined ? cookies.get(CONSENT_COOKIES) === "true": defaultValue;
  }

  static setConsentCookie(defaultValue = false) {
    defaultValue === true ? cookies.set(CONSENT_COOKIES, "true", cookiesOptions) : cookies.remove(CONSENT_COOKIES);
  }

  static hasConsentStatsCookie(defaultValue = false) {
    return cookies.get(CONSENT_STATS_COOKIES) !== undefined ? cookies.get(CONSENT_STATS_COOKIES) === "true": defaultValue;
  }

  static setConsentStatsCookies(defaultValue = false) {
    defaultValue === true ? cookies.set(CONSENT_STATS_COOKIES, "true", cookiesOptions) :
                            cookies.set(CONSENT_STATS_COOKIES, "false", cookiesOptions);
  }

  static userErrorOf(action, t, apiError) {
    const strError = isObject(apiError) ? JSON.stringify(apiError) : apiError ;
    console.error(`${action} : ${strError}`);
    if ("NOT_FOUND" === apiError.code) {
      if (apiError.details) {
        console.info(apiError.details);
      }
      return t('apiError.notFound');
    }
    if ("INVALID_PARAMETER" === apiError.code) {
      return `${t('apiError.invalidParameter')} ${apiError.details}`;
    }
    if (apiError.details && apiError.details.startsWith('apiError.')) {
      return `${action} : ` + t(apiError.details);
    }
    if (apiError.details) {
      return `${action} : ` + apiError.details;
    }
    if (apiError.message) {
      return `${action} : ` + apiError.message;
    }
    return `${action} : ` + strError;
  }
}

export default CUtils;

const isObject = (a) => (!!a) && (a.constructor === Object);
const removeAccent = (value) => value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
const notEmptyArray = (value) => value && Array.isArray(value) && value.length > 0;