import dayjs from 'dayjs';
import React, {Component} from 'react';
import ApiV0 from '../services/ApiV0';
import CUtils from '../services/CUtils';
import MetaTags from 'react-meta-tags';
import {Alert, Card, Container, Row, Col} from 'react-bootstrap';
import CListingDetails from './CListingDetails';
import CListingShareButtons from './CListingShareButtons';
import CListingNext from './CListingNext';
import CListingGallery from './CListingGallery';
import CBreadcrumb from '../common/CBreadcrumb';
import CLoadInProgress from '../common/CLoadInProgress';
import './CListing.css';
import {CConstants} from '../common/CConstants';
import {analyticsPageView, analyticsEvent} from '../core/AnalyticsManager';
import {withTranslation} from 'react-i18next';
import _ from 'lodash';

const KEY_DOWN_ARROW = 40;
const DEBUG_CLISTING = false;

class CListing extends Component {
    state = {
        downCount: 0,
        errorMessage: null,
        infoMessage: null,
        selectedImageId: null,
        showAdmin: false
    }

    componentDidMount() {
        this._refocus();
        document.addEventListener("keydown", this._handleKeyDown);
        document.body.addEventListener('ch-toggle-admin', this.handleToggleAdminChange.bind(this));
        this.getSection(() => this.getListing(() => this._refocus()));
    }


    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyDown);
        document.body.removeEventListener('ch-toggle-admin', this.handleToggleAdminChange.bind(this));
    }

    _handleKeyDown = (event) => {
        // DEBUG // console.log(event.keyCode)
        switch (event.keyCode) {
            case KEY_DOWN_ARROW:
                this.onDown();
                break;
            default:
                break;
        }
    }

    handleToggleAdminChange(event) {
        this.setState({showAdmin: !this.state.showAdmin});
    }

    onDown() {
        this.setState({downCount: this.state.downCount + 1}, () => {
            if (this.state.downCount % 30 === 0) {
                window.open(this.state.listing.url, "_etsy");
            }
        });
    }

    _refocus() {
        if (!this.state.listing || this.state.pv === true) {
            return;
        }
        const {t} = this.props;
        this.setState({pv: true}, () => {
            const lang = ApiV0.getLang();
            const listing = this.state.listing;
            document.title = t('listing.title', {shortTitle: CUtils.getListingShortTitle(listing, 50, lang)});
            analyticsPageView('product ' + this.state.listing.listing_id + ' - ' + CUtils.getListingShortTitle(listing, 150, lang));
        });
    }

    getSection(cb) {
        const {t} = this.props;
        const sectionId = this.props.sectionId;
        if (window.isSet(sectionId)) {
            ApiV0.getSection(sectionId)
                .then(sectionResult => {
                    DEBUG_CLISTING && console.log("sectionResult", sectionResult);
                    this.setState({section: sectionResult, errorMessage: null, infoMessage: null}, cb);
                })
                .catch(getErrorMessage => {
                    DEBUG_CLISTING && console.log("getErrorMessage", getErrorMessage)
                    const userError = CUtils.userErrorOf("getSection", t, getErrorMessage);
                    this.setState({errorMessage: userError}, cb);
                });
        } else {
            console.warn(`CListing : no sectionId`);
            cb();
        }

    }

    getListing(cb) {
        const {t} = this.props;
        const returnTo = window.location.href;
        ApiV0.getListing(this.props.listingId, {returnTo})
            .then(listingResult => {
                this.setState({listing: listingResult, errorMessage: null, infoMessage: null}, cb);
            })
            .catch(apiError => {
                const errorMessage = CUtils.userErrorOf(t('listing.error.listingError'), t, apiError);
                this.setState({errorMessage, listing: null}, cb);
            });
    }

    decodedTitle() {
        return CUtils._(this.state.listing.title);
    }

    getSectionName() {
        const lang = ApiV0.getLang();
        return CUtils.getSectionTitle(this.state.section, lang);
    }

    onSelectVariation(vName, vValue, vImage, userGenerated) {
        // DEBUG // console.info("onSelectVariation name:", vName, " value:", vValue, " vImage:", vImage);
        const lang = ApiV0.getLang();
        const listing = this.state.listing;
        if (listing) {
            if (userGenerated === true) {
                const evt = 'product ' + this.state.listing.listing_id + ' select ' + vName + ' ' + vValue + ' - ' +
                    CUtils.getListingShortTitle(listing, 100, lang);
                analyticsEvent(CConstants.GG_CATEGORY.LISTING, evt, evt, vValue);
            }
            this.setState({"selectedImageId": vImage});
        }
    }

    onCartError(msg) {
        this.setState({errorMessage: msg});
    }

    getNext() {
        return _.get(this.state.listing, 'context.next', null);
    }

    render() {
        const {t, isAdmin} = this.props;
        const lang = ApiV0.getLang();
        const listing = this.state.listing;
        const section = this.state.section;

        const errorPlace = this.state.errorMessage ? (
            <Alert variant="warning">{this.state.errorMessage}</Alert>) : null;

        const sectionTitle = this.getSectionName();
        const sectionHref = section && section.ch_listing_count > 0 ? CUtils.getSectionLink(section, lang) : null;

        const productTitle = CUtils.getListingShortTitle(listing, 150, lang);
        const productFullTitle = listing && 'fr' === lang ? listing.title : listing ? listing.en_title : null;
        const shortTitle = CUtils.getListingShortTitle(listing, 70, lang);
        const productTags = CUtils.getListingTags(listing, 3);
        const productImage = CUtils.getListingImage(listing);
        const productUrl = CUtils.getListingLink(listing, lang);
        const productFullUrl = "https://www.creharmony.fr" + productUrl;

        const next = this.getNext();
        const breadcrumb =
            (<CBreadcrumb secondLabel={section ? sectionTitle : null} secondLink={sectionHref}
                          activeLabel={listing ? productTitle : null}
                          activeLink={listing ? productUrl : null} activeClass="largeScreen"/>);

        if (!listing) {
            return errorPlace ? (<>{breadcrumb}{errorPlace}</>) : (<CLoadInProgress/>);
        }
        const decodedDescription = CUtils.getListingDescription(listing, lang);
        const metaDescription = CUtils.getListingMetaDescription(listing, lang);
        const tagsComma = CUtils.getListingTagsComaSeparated(listing, lang);
        const imageUrl = CUtils.listingMainImageUrl(listing);
        const availability = window.isSet(listing.price) && listing.quantity > 0 ? "https://schema.org/InStock" : "https://schema.org/OutOfStock";
        const endTzDate = listing.ending_tsz ? new Date(listing.ending_tsz * 1000) : null;
        const priceValidUntil = endTzDate ? endTzDate.toISOString().substring(0, 10) : dayjs().add(30, 'day').format('YYYY-MM-DD');
        ;
        const listingGallery = notEmptyArray(listing.images) ? (<CListingGallery listing={listing}
                                                                                 selectedImageId={this.state.selectedImageId}
                                                                                 title={shortTitle}/>) : null;

        const shareTag = productTags ? "#" + productTags[0] : null;// The hashtag should include the hash symbol.

        const listingDetails = (<CListingDetails listing={listing}
                                                 title={productTitle}
                                                 tags={productTags}
                                                 image={productImage}
                                                 sectionTitle={sectionTitle}
                                                 sectionHref={sectionHref}
                                                 onSelectVariation={this.onSelectVariation.bind(this)}
                                                 onCartUpdated={this.props.onCartUpdated}
                                                 onCartError={this.onCartError.bind(this)}
                                                 cartFindEntry={this.props.cartFindEntry}
                                                 cartEntryQuantity={this.props.cartEntryQuantity}
                                                 isConnected={this.props.isConnected}
                                                 isAdmin={isAdmin}
                                                 url={productFullUrl}
        />);
        const listingNext = (<CListingNext next={next}/>);
        const listingTitle = (<Card.Text className="clistingTitle">
            <span itemProp="name">{productTitle}</span>
        </Card.Text>);
        const showAdminItems = isAdmin && this.state.showAdmin;
        const listingDescription = (<Card id="listingDesc" className="clistingDescription">
            <Card.Text>
                                             <span itemProp="description">
                                             {showAdminItems ? (<div className="adminOnlyFeature">
                                                 <i><b>Admin titre complet : </b>{productFullTitle}</i><br/>
                                             </div>) : null}
                                                 {decodedDescription}
                                             </span>
                <span className="hidden" itemProp="image">{imageUrl}</span>
            </Card.Text>
        </Card>);
        const listingSharedButtons =
            (<CListingShareButtons
                listingUrl={productFullUrl}
                shareTitle={productTitle}
                shareTags={productTags} // array of word without accent and with camelCase
                shareTag={shareTag}
                shareIconSize={30}
                shareVia="creharmony"
                shareMedia={productImage}
                sharedOn={t('listing.details.shareOn')}
            />);
        // check github head META as example
        // twitter ID for Creharmony : https://tools.codeofaninja.com/find-twitter-id
        const metaTags = (<MetaTags>
            <link rel="canonical" href={productFullUrl}></link>
            <meta name="og:url" content={productFullUrl}></meta>
            <meta name="og:site_name" content="Creharmony"></meta>
            <meta name="og:title" content={productTitle}></meta>
            <meta name="og:image:type" content="image/jpeg"></meta>
            <meta name="og:image:width" content="2816"></meta>
            <meta name="og:image:height" content="1880"></meta>
            {imageUrl ? (<meta name="og:image" content={imageUrl}></meta>):null}
            <meta name="twitter:site" content="Creharmony"></meta>
            <meta name="twitter:site:id" content="3182113797"></meta>
            <meta name="twitter:creator" content="Creharmony"></meta>
            <meta name="twitter:creator:id" content="3182113797"></meta>
            <meta name="twitter:card" content="summary_large_image"></meta>
            <meta name="twitter:title" content={productTitle}></meta>
            <meta name="twitter:description" content={metaDescription}></meta>
            {imageUrl ? (<meta name="twitter:image:src" content={imageUrl}></meta>):null}
            <meta name="twitter:image:width" content="2816"></meta>
            <meta name="twitter:image:height" content="1880"></meta>
            <meta name="twitter:url" content={productFullUrl}></meta>
            <meta name="keywords" id="meta-keywords" content={tagsComma}></meta>
            <meta name="description" content={metaDescription}></meta>
        </MetaTags>);
        const ListingHiddenForSEO = // this section seems to be scrapped by google bot to create thumbnail
            (<div className="hidden">

                <div itemProp="offers" itemScope itemType="https://schema.org/Offer">
                    <table>
                        <tbody>
                        {imageUrl ? (
                            <tr>
                                <td>Thumbnail :</td>
                                <td><img itemProp="image" src={imageUrl} alt={this.decodedTitle()}/></td>
                            </tr>
                        ) : null}
                        <tr>
                            <td>Url :</td>
                            <td><span itemProp="url">{productFullUrl}</span></td>
                        </tr>
                        <tr>
                            <td>SameAs :</td>
                            <td><span itemProp="sameAs">{productFullUrl}</span></td>
                        </tr>
                        <tr>
                            <td>Availability :</td>
                            <td><span itemProp="availability">{availability}</span></td>
                        </tr>
                        <tr>
                            <td>Price :</td>
                            <td><span itemProp="price">{listing.price}</span></td>
                        </tr>
                        <tr>
                            <td>Currency :</td>
                            <td><span itemProp="priceCurrency">{listing.currency_code}</span></td>
                        </tr>
                        <tr>
                            <td>Price validity until :</td>
                            <td><span itemProp="priceValidUntil">{priceValidUntil}</span></td>
                        </tr>
                        <tr>
                            <td>Created :</td>
                            <td><span itemProp="dateCreated">{listing.creation_tsz}</span></td>
                        </tr>
                        <tr>
                            <td>Published :</td>
                            <td><span itemProp="datePublished">{listing.original_creation_tsz}</span></td>
                        </tr>
                        <tr>
                            <td>Modified :</td>
                            <td><span itemProp="dateModified">{listing.last_modified_tsz}</span></td>
                        </tr>
                        <tr>
                            <td>Keywords :</td>
                            <td><span itemProp="keywords">{tagsComma}</span></td>
                        </tr>
                        <tr>
                            <td>Identifier :</td>
                            <td><span itemProp="identifier">{listing.listing_id}</span></td>
                        </tr>
                        <tr>
                            <td>Sku :</td>
                            <td><span itemProp="sku">{listing.listing_id}</span></td>
                        </tr>
                        <tr>
                            <td>ProductID :</td>
                            <td><span itemProp="productID">{listing.listing_id}</span></td>
                        </tr>
                        <tr>
                            <td>Brand :</td>
                            <td>
                                <div itemProp="brand" itemScope itemType="https://schema.org/Brand">
                                    <span itemProp="name">Creharmony</span><br/>
                                </div>
                            </td>
                        </tr>
                        { window.isSet(listing.weight) ?
                            (<tr>
                                 <td>OfferShippingDetails :</td>
                                 <td>
                                     <div itemProp="shippingDetails" itemScope itemType="https://schema.org/OfferShippingDetails">
                                         <div itemProp="weight" itemScope itemType="https://schema.org/QuantitativeValue">
                                             <span itemProp="unitCode">GRM</span><br/>{/* UN/CEFACT Common Code */}
                                             <span itemProp="value">{listing.weight}</span><br/>
                                         </div>
                                     </div>
                                 </td>
                             </tr>) : null }
                        </tbody>
                    </table>
                </div>


            </div>);

        const ListingShowedContent =
            (<Container>
                <div className="largeScreen">
                    <Row>
                        <Col xs={8}>
                            {listingGallery}
                            {listingDescription}
                        </Col>
                        <Col xs={4}>
                            {listingDetails}
                            {listingSharedButtons}
                        </Col>
                    </Row>
                </div>

                <div className="smallScreen">
                    <Row>{listingTitle}</Row>
                    <Row>{listingGallery}</Row>
                    <Row>{listingDetails}</Row>
                    <Row>{listingDescription}</Row>
                    <Row>{listingSharedButtons}</Row>
                </div>

                <Row>{listingNext}</Row>
            </Container>);

        return (
            <div className="clisting"
                 itemProp="mainEntity"
                 itemScope
                 itemType="https://schema.org/Product">
                {metaTags}
                {breadcrumb}
                {/* (section {this.props.sectionId} ) / ( product {this.props.productId} ) */}

                {errorPlace}
                {listing ? (<div>
                    {ListingShowedContent}
                    {ListingHiddenForSEO}
                </div>) : (<CLoadInProgress/>)}
            </div>
        );
    }
}

export default withTranslation('common')(CListing);

const notEmptyArray = (value) => value && Array.isArray(value) && value.length > 0;