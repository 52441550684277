import React, { Component } from 'react';
import { Card, Table } from 'react-bootstrap';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { FaCaretLeft } from 'react-icons/fa';
import { TiDelete } from 'react-icons/ti';
import { CConstants } from '../common/CConstants';
import CPrice from '../common/CPrice';
import CEditCountry from '../auth/CEditCountry';
import { analyticsEvent } from '../core/AnalyticsManager';
import ApiV0 from '../services/ApiV0';
import CCodePromo from './CCodePromo'
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

class CCartPayment extends Component {

  onUpdated(msg) {
    if (this.props.onUpdated === undefined) {
      console.error("CCartPayment: onUpdated not defined");
      return;
    }
    console.info(`CCartPayment: onUpdated : ${msg}`);
    this.props.onUpdated(msg);
  }

  onRemoveCodePromo() {
    const { t } = this.props;
    const displayName = this.props.meProfile.displayName;
    ApiV0.removePromotionCode()
    .then((success) => {
        const evt = `remove promotion code`;
        analyticsEvent(CConstants.GG_CATEGORY.AUTH, evt, evt, displayName);
        this.onUpdated(t('cart.payment.removePromo'));
    })
    .catch((err) => {
        if (404 === err) {// loose session
          this.setState({errorMessage: t('common.sessionLost')}, () => document.location.href ="/login");
          return;
        }
        console.info("remove promotion code error: "+ err);
        const evt = `remove promotion code error`;
        analyticsEvent(CConstants.GG_CATEGORY.AUTH, evt, evt, displayName);
        this.onUpdated(t('cart.payment.removePromoError'));
    });
  }

  render() {
    const { t } = this.props;
    const meProfile = _.get(this.props, 'meProfile', null);
    var country = _.get(this.props, 'meProfile.country', null);
    country = country !== null ? t(`countries.${country}`) : null;
    if (!this.isSet(meProfile)) {
      return (
      <div className="cartPayment">
        <div className="paymentDetails">
             {t('cart.payment.loginRequired')} <a href="/login" className="txt">{t('cart.payment.loginLink')}</a>.
        </div>
      </div>
      )
    }
    if (!this.isSet(this.props.payment) || !this.isSet(country)) {
      return (
      <div className="cartPayment">
        <Card.Title>{t('cart.payment.calculation')}</Card.Title>
        <div className="paymentDetails">
             {t('cart.payment.countryRequired')}<br/>
             <CEditCountry meProfile={meProfile}
                           updated={this.onUpdated.bind(this, t('cart.payment.countryUpdated'))}/>
        </div>
      </div>
      )
    }
    const payment = this.props.payment;
    const promotion = this.props.payment.promotion;
    const cartCount = payment && payment.count ? payment.count : 0;
    const onCommand = this.props.onCommand;
    const submitDisabled = false;
    const nbArticle = `${cartCount} ${t('cart.payment.product', {count: cartCount})}`;
    const isModeCart = this.props.mode === 'cart';
    const isPaymentPossible = this.isSet(payment) &&
                              this.isSet(payment.listings_price) &&
                              this.isSet(payment.country) &&
                              this.isSet(payment.shipping_price) &&
                              this.isSet(payment.total_price)

    return payment && payment.count && payment.count < 1 ? null : isPaymentPossible ? (
      <div className="cartPayment">
        { isModeCart ? (<Card.Title>{t('cart.payment.cartPrice')}</Card.Title>):null}
        <div className="paymentDetails">
             <Table striped bordered size="sm">
               <tbody>
                 <tr>
                    <td>{t('cart.payment.cartProductsPrice')}:</td>
                    <td className="noWrap alignRight"><CPrice price={payment.listings_price} currency="€" /></td>
                 </tr>
                 { promotion && promotion.value ? (
                   <tr>
                        <td>{t('cart.payment.promoCode')} ({promotion.discount})</td>
                        <td className="noWrap alignRight">
                             <CPrice price={promotion.value} currency="€" />
                             <span data-tooltip-content={t('cart.payment.removePromoTip')} id="ttPaymentPromo"
                                   onClick={this.onRemoveCodePromo.bind(this)}
                                   style={{cursor: 'pointer'}}>
                              &#160;<TiDelete/>
                             </span>
                             <ReactTooltip anchorId="ttPaymentPromo"/>
                        </td>
                   </tr>
                 ) : null}
                 <tr><td colSpan="2"><CCodePromo meProfile={meProfile} onUpdated={this.onUpdated.bind(this)} /></td></tr>
                 <tr><td>{t('cart.payment.cartShippingPrice', {country})}:</td><td className="noWrap alignRight"><CPrice price={payment.shipping_price} currency="€" /></td></tr>
                 <tr><td><b>{t('cart.payment.total')}</b> ({nbArticle})</td><td className="noWrap alignRight"><CPrice price={payment.total_price} currency="€" /></td></tr>
               </tbody>
             </Table>
             {isModeCart ? (<span>
               <ReactTooltip anchorId="ttPayment"/>
               <div style={{ float: 'right' }} data-tooltip-content={t('cart.payment.continueCommand')} id="ttPayment">
                 <button type="button" className="btn btn-secondary"
                         onClick={onCommand}
                         title={t('cart.payment.submit')}
                         disabled={submitDisabled}>
                   {t('cart.payment.submit')}
                 </button>
               </div>
               <a href="/"><FaCaretLeft/> {t('cart.payment.continueShop')}</a>&#160;
             </span>):null}
        </div>
      </div>
    ) : (
      <div className="cartPayment">
        <div className="paymentDetails">
              {t('cart.payment.tooHeavy1')}<br/>
              {t('cart.payment.tooHeavy2')}<br/>
              {t('cart.payment.tooHeavy3')} <a href="/contact">{t('cart.payment.contactUs')}</a>.
        </div>
      </div>
    );
  }

  isSet(variable) {
    return (variable !== undefined && variable !== null);
  }

}

export default withTranslation('common')(CCartPayment);