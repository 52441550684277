import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import './CListingAdminAction.css';
import { Button } from 'react-bootstrap';
import SimpleDateTime  from 'react-simple-timestamp-to-date';
import CDuplicateListingButton from '../common/CDuplicateListingButton';
import CEditListingButton from '../common/CEditListingButton';
import CDeleteListingButton from '../common/CDeleteListingButton';
import CShippingType from '../common/admin/CShippingType';
import CTimeAgo  from '../common/admin/CTimeAgo';
import ApiV0 from '../services/ApiV0';
import CUtils from '../services/CUtils';
import CListingCommandsAll  from './CListingCommandsAll';

class CListingAdminAction extends Component {

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  onDeleteListing(listingId, listingTitle) {
    const { t, listing } = this.props;
    const admin = this;
    const sectionId = listing.shop_section_id;
    if (window.confirm("Voulez-vous supprimer l'article suivant ? " + listingTitle)) {
      admin.setState({ loading: true }, () => {
        ApiV0.deleteListing(listingId)
          .then(nbDeleted => {
            document.location = `/listing/${sectionId}`;
          })
          .catch(apiError => {
            const errorMessage = CUtils.userErrorOf("Impossible de supprimer l'article", t, apiError);
            alert(errorMessage);
          });
      })
    }
  }

  onShowCommand(command){
    const commandId = command.command_id;
    console.log("onShowCommand " +  commandId);
    document.location.href = "/admin?showType=command&showId="+commandId;
  }

  render() {
   const { t, isAdmin, listing } = this.props;
   if (isAdmin !== true) {
     return null;
   }
   const sectionId = listing.shop_section_id;
   const listingId = listing.listing_id;
   const listingUrl = listing.url;
   const listingTitleTooltip = CUtils._(listing.title);
   const weight = listing.weight;
   const shippingType = listing.shippingType;
   const commands = listing.commands;
   const commandsCount = commands ? commands.length : 0;
   const buttonSize="m";
   const lang = ApiV0.getLang();
   const tags = lang === 'fr' ? listing.tags : listing.en_tags;
   return (
     <div>
        <div className="adminOnlyFeature">
            <b><i>Admin</i></b><br/>
            {window.isSet(listingUrl) ?(<>
              <Button variant="light" size={buttonSize} style={{ margin: 5, padding: 2 }}>
                <a href={listingUrl} target="_etsy">Fiche Etsy</a>
              </Button><br/>
            </>):null}
            { listing.creation_tsz ? (<>Créé le <SimpleDateTime dateFormat="DMY" dateSeparator="/" timeSeparator=":">{listing.creation_tsz}</SimpleDateTime><br/></>):null}
            { listing.updatedTs ? (<>Modifié il y a <CTimeAgo timestamp={listing.updatedTs*1000}/><br/></>):null}
            { listing.listingSyncTs ? (<>Sync. il y a <CTimeAgo timestamp={listing.listingSyncTs*1000}/><br/></>):null}
            { listing.expireAt ? (<>Expire dans <CTimeAgo timestamp={new Date(listing.expireAt).getTime()}/><br/></>):null}
            { weight ? (<>Livraison : {weight}g <CShippingType type={shippingType} /></>):null}<br/>
            <CDuplicateListingButton isAdmin={isAdmin} sectionId={sectionId} listingId={listingId} size={buttonSize}/>
            <CEditListingButton isAdmin={isAdmin} sectionId={sectionId} listingId={listingId} size={buttonSize}/>
            <CDeleteListingButton t={t} isAdmin={isAdmin} listingId={listingId} listingTitle={listingTitleTooltip}
                                  onDeleteListing={this.onDeleteListing.bind(this)} />

{/*
              /admin?showType=command&showId=6175a6c18e03c61874705055
*/}
            {commandsCount > 0 ? (<>
                <h2>{commandsCount} commande(s)</h2>
                <CListingCommandsAll commands={commands} onShowCommand={this.onShowCommand.bind(this)}/>
            </>) :null}
            {tags && tags.length > 0 ? (<>
                <h2>{tags.length} tag(s)</h2>
                { tags.map( (val, index) => { return (
                     <span key={index} index={index} className="listingTag">{val}</span>)
                }) }
            </>) :null}
        </div>
     </div>
   );
  }

}

export default withTranslation('common')(CListingAdminAction);