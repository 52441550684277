import React, { Component } from 'react'
import './CHead.css';
import { withTranslation } from 'react-i18next';

class CHead extends Component {

  render() {
    const { t } = this.props;
    return (
    <a href="/">
        <div id="conteneur">
            <img src="/images/top_bandeau_bougies_crane2.webp" alt={t('ch.head')}/>
            <div id="masque">
                <div className="chead">
                  <div className="siteName">{t('head.title')}</div>
                  <div className="chright subtitle">{t('head.right')}</div>
                  <div className="chleft subtitle">{t('head.left')}</div>
                </div>
            </div>
        </div>
    </a>
    );
  }
}

export default withTranslation('common')(CHead);