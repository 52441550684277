import React from "react";
import './CFooter.css';
import {SiFacebook, SiInstagram} from 'react-icons/si';
import {RiTwitterXLine} from "react-icons/ri";
import {CConstants} from '../common/CConstants';
import {Nav, Navbar} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';
import {Tooltip as ReactTooltip} from 'react-tooltip';
class CFooter extends React.Component {

    render() {
        const {t} = this.props;
        return (<>
            <div className="footerIcons">
                <a className="footerIcon" target="_social"
                   href="https://www.facebook.com/creharmony"
                   data-tooltip-content="Facebook: creharmony" id="ttFooterTooltipFB"><SiFacebook/></a>
                <a className="footerIcon" target="_social"
                   href="https://twitter.com/creharmony"
                   data-tooltip-content="X (ex. Twitter): creharmony"
                   id="ttFooterTooltipX"><RiTwitterXLine/></a>
                <a className="footerIcon" target="_social"
                   href="https://www.instagram.com/_creharmony_"
                   data-tooltip-content="Instagram: _creharmony_" id="ttFooterTooltipIg"><SiInstagram/></a>
                <ReactTooltip anchorId="ttFooterTooltipFB"/>
                <ReactTooltip anchorId="ttFooterTooltipX"/>
                <ReactTooltip anchorId="ttFooterTooltipIg"/>
            </div>
            <div className="clearBoth"></div>
            <Navbar expand="md" ref={this.wrapper} className="justify-content-center">
                <Nav><a className="footerSection" href="/about">{t('common.legal')}</a></Nav>
                <Nav>|</Nav>
                <Nav><a className="footerSection" href="/cgv"
                        title={t('common.conditionsFull')}>{t('common.cgv')}</a></Nav>
                <Nav>|</Nav>
                <Nav><a className="footerSection" href="/livraison">{t('common.shipping')}</a></Nav>
                <Nav>|</Nav>
                <Nav><a className="footerSection" href="/who">{t('common.who')}</a></Nav>
                <Nav>|</Nav>
                <Nav><a className="footerSection" href="/contact">{t('common.contact')}</a></Nav>
                <Nav>|</Nav>
                <Nav><a className="footerSection" href="/donnees">{t('common.personalData')}</a></Nav>
                <Nav>|</Nav>
                <Nav><a className="footerSection" href="/cookies"
                        title={t('common.cookiesTitle')}>{t('common.cookies')}</a></Nav>
                <Nav>|</Nav>
                <Nav>{CConstants.CH_COPYRIGHTS}</Nav>
            </Navbar>
        </>);
    }
}

export default withTranslation('common')(CFooter);