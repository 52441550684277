import React, { Component } from 'react'
import './CMenu.css';
import { Badge, Container, Navbar, Nav, NavLink, NavDropdown } from 'react-bootstrap';
import { GiExitDoor } from 'react-icons/gi';
import { RiAdminFill, RiAccountPinCircleFill} from 'react-icons/ri';
import { HiOutlineClipboardList} from 'react-icons/hi';
import { MdShoppingCart } from 'react-icons/md';
// sections // import { GoTriangleDown } from 'react-icons/go';
import CUtils from '../services/CUtils'
import CSearchBar from './CSearchBar';
import ApiV0 from '../services/ApiV0';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { CConstants } from '../common/CConstants';
import { analyticsEvent } from './AnalyticsManager';
import _ from 'lodash';

import i18n from '../i18n.js';
import { withTranslation } from 'react-i18next';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class CMenu extends Component {

  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.state = {};
  }

  isConnected() {
   return (this.props.me && this.props.me.profile && this.props.me.profile.displayName)
  }

  isAdmin() {
   return (this.isConnected() && this.props.me.profile.isAdmin === true)
  }

  show(menuEntry) {
    let state = this.state;
    state[menuEntry] = true;
    this.setState(state);
  }

  hide(menuEntry) {
    let state = this.state;
    state[menuEntry] = false;
    this.setState(state);
  }

  toggleLang(newLang) {
    if (!CConstants.LANG_ACCEPTED.includes(newLang)) {
      console.warn(`unable to setup lang to ${newLang}. Accepted languages are ${CConstants.LANG_ACCEPTED}`);
      return;
    }
    i18n.changeLanguage(newLang);
    cookies.set(CConstants.COOKIE_LANG_KEY, newLang, CConstants.COOKIE_DEFAULT_SET_OPTIONS);
    ApiV0.setLang(newLang);
  }

  logoutUser() {
     const propsMe = this.props.me;
     const isConnected = propsMe && propsMe.profile;
     const connectedUser = isConnected ? propsMe.profile.displayName : "Anonyme";
     const evt = `logout`;
     analyticsEvent(CConstants.GG_CATEGORY.AUTH, evt, evt, connectedUser);
     document.location.href ="/api/v0/logout";
  }

  render() {
    if (!this.props.sections) {
      return null;
    }
    const { t } = this.props;
    const lang = ApiV0.getLang();
    const propsMe = this.props.me;
    const isConnected = propsMe && propsMe.profile;
    const cart = propsMe.cart ? propsMe.cart : null;
    const connectedUser = isConnected ? propsMe.profile.displayName : t('menu.anonymous');

    const reducerAdd = (accumulator, currentValue) => accumulator + currentValue;
    const validEntries = _.get(cart, 'entries', []).filter( e => e.quantity > 0 && e.quantityMax > 0);
    const cartCount = validEntries.map( e => (Math.min(e.quantity,e.quantityMax))).reduce(reducerAdd, 0);

    const hasCommands = isConnected && propsMe.commands && propsMe.commands.length > 0;

    const activeKey =  window.location.pathname; // "/"
    const sections = this.props.sections;
    const amIAdmin = this.isAdmin();
    const adminActive = window.location.pathname.startsWith('/admin');
    const commandActive = window.location.pathname.startsWith('/command');

    const navDropdownAccountTitle = (<span><RiAccountPinCircleFill/> {t('menu.account')}</span>);

    const smallScreenLangToggle = (<>
      <Nav><img src="/flags/gb.svg" alt="en" className="flag-img" onClick={this.toggleLang.bind(this, 'en')}/></Nav>
      <Nav><img src="/flags/fr.svg" alt="fr" className="flag-img" onClick={this.toggleLang.bind(this, 'fr')}/></Nav>
    </>);

    const smallConnectMenu = this.isConnected() ?
                      (<>
                        <Nav><a href="/me"><RiAccountPinCircleFill/> {t('menu.profile')}</a></Nav>
                        { hasCommands ?
                          (<Nav><a href="/command/all"><HiOutlineClipboardList/> {t('menu.commands')}</a></Nav>)
                          : null}
                      </>):
                      (<Nav><a href="/login">{t('menu.login.label')}</a></Nav>);

    const largeScreenLangToggle = (<span className="largeScreen">
        <ReactTooltip anchorId="menuEnBis"/>
        <ReactTooltip anchorId="menuFrBis"/>
        <Nav>
          <span onClick={this.toggleLang.bind(this, 'en')}
                className="flag-icon flag-icon-gb"
                data-tooltip-content="Use English as language" id="menuEnBis"/>
          &#160;
          <span onClick={this.toggleLang.bind(this, 'fr')}
                className="flag-icon flag-icon-fr"
                data-tooltip-content="Utiliser le Français comme langue" id="menuFrBis"/>
          &#160;
        </Nav>
    </span>);

    const largeConnectMenu = this.isConnected() ? (
        <Nav className="largeScreen">

          <NavDropdown title={navDropdownAccountTitle}
              show={this.state.account}
              onMouseEnter={this.show.bind(this,'account')}
              onMouseLeave={this.hide.bind(this,'account')}
              className="largeScreen"
              >
            { hasCommands ? (
              <NavDropdown.Item as={NavLink}
                        active={commandActive}
                        href="/command/all"
                        ><HiOutlineClipboardList/> {t('menu.commands')}</NavDropdown.Item>
              ) : null
            }
            <NavDropdown.Item as={NavLink} href="/me"
                    ><RiAccountPinCircleFill/> {t('menu.profile')} - {connectedUser}</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} onClick={this.logoutUser.bind(this)}
                    ><GiExitDoor/> {t('menu.logout')}</NavDropdown.Item>
          </NavDropdown>

        </Nav>
        ) : (<>
             <ReactTooltip anchorId="menuSubscribeBis"/>
             <ReactTooltip anchorId="menuLoginBis"/>
             <Nav.Link as={NavLink}
                       href="/register"
                       data-tooltip-content={t('menu.subscribe.tooltip')} id="menuSubscribeBis"
                       className="largeScreen"
                       >{t('menu.subscribe.label')}</Nav.Link>
             <Nav.Link as={NavLink}
                       href="/login"
                       data-tooltip-content={t('menu.login.tooltip')} id="menuLoginBis"
                       className="largeScreen"
                       >{t('menu.login.label')}</Nav.Link>
        </>);

    const smallScreenSections = (<Nav className="mr-auto">
            <Nav.Link href="/" className="cMenuEntry">{t('ch.home')}</Nav.Link>
            { sections.map( (s, key) => {
               const sectionRef = CUtils.getSectionLink(s, lang);
               const title = CUtils.getSectionTitle(s, lang);
               return (
                <Nav defaultActiveKey={activeKey} className="flex-column" key={key}>
                  <Nav.Link href={sectionRef} eventKey={sectionRef} className="cMenuEntry">
                    {title}
                  </Nav.Link>
                </Nav>
               )
            }) }
          </Nav>)

    return (<>

      {/* small screen nav menu */}
      <Navbar bg="light" expand="lg" ref={this.wrapper} className="smallScreen">
       <Container fluid>
        {smallScreenLangToggle}
        { amIAdmin ? (<Nav><a href="/admin"><RiAdminFill/> admin</a></Nav>) : null}
        { smallConnectMenu }
        {cartCount > 0 ?
        (<>
          <Nav><a href="/cart"><MdShoppingCart/> {t('menu.cart')}<Badge bg="light" className="chBadge cartBadge">{cartCount}</Badge></a></Nav>
         </>)
        : null}
        <Nav className="mr-auto"/>
        <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <CSearchBar/>
          {smallScreenSections}
        </Navbar.Collapse>
       </Container>
      </Navbar>

      {/* large screen nav menu  -------------------------------------------- */}
      <Navbar bg="light" expand="lg" ref={this.wrapper} className="cmenu largeScreen">
        <Nav className="mr-auto"/>
        <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          {largeScreenLangToggle}
          <CSearchBar/>
          <Nav>
            { amIAdmin ? (
              <Nav.Link as={NavLink} active={adminActive} href="/admin"><RiAdminFill/> {t('menu.admin')}</Nav.Link>
              ) : null
            }
            {largeConnectMenu}
            <Nav.Link title={t('menu.mycart')} disabled={cartCount < 1} href="/cart">
               <MdShoppingCart/> {t('menu.cart')}<Badge bg="light" className="chBadge cartBadge">{cartCount}</Badge>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      </>
    );
  }

}


export default withTranslation('common')(CMenu);