
const listingIdMinChId = 4000000000000;
const sectionIdMinChId = 100000000;
/**
 * return true when val is set and not an empty string
 **/
window.isSet = (val) => (val !== undefined && val !== "undefined" && val !== null && val !== '' && val !== 'null');

/**
 * return true when listingId is a ch id
 **/
window.isChListing = (listingId) => (parseInt(listingId, 10) >= listingIdMinChId);

/**
 * return true when sectionId is a ch id
 **/
window.isChSection = (sectionId) => (sectionId >= sectionIdMinChId);