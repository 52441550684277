import React, { Component } from 'react';
import Select from 'react-select'; // https://react-select.com/home
import { withTranslation } from 'react-i18next';

import ApiV0 from '../services/ApiV0';

const DEBUG_COMPONENT = false;

class CListingCustomization extends Component {
  state = {
      errorMessage: null,
      infoMessage: null,
      customization_value : { "choices" : []},
      isValid: false
  }

  handleLangChange(event) {
    // console.log("lang has changed to", event.detail.newLang);
    const customization_value = { "choices" : []};
    this.setState({customization_value});
  }

  componentDidMount() {
    document.body.addEventListener('ch-set-lang', this.handleLangChange.bind(this));
  }

  componentWillUnmount() {
    document.body.removeEventListener('ch-set-lang', this.handleLangChange.bind(this));
  }


  getNumberOfEntries() {
    var customization_value = this.state.customization_value;
    if (!customization_value["choices"]) {
      return 0;
    }
    const reducerAdd = (accumulator, currentValue) => accumulator + currentValue;
    const entryCountOf = (entry) => Array.isArray(entry) ? entry.length : 1;
    return Object.keys(customization_value["choices"])
                .map(name => entryCountOf(customization_value["choices"][name]))
                .reduce(reducerAdd, 0);
  }

  /** prepare the value to send to the parent : rework selected value to array */
  getCustomizationValue() {
    const customization = this.state.customization_value;
    if (!customization["choices"]) {
      return null;
    }
    for (var name of Object.keys(customization["choices"])) {
       if (name.endsWith("_invalid") && window.isSet(customization["choices"][name])) {
         return null;// at least one field is not valid
       }
    }

    var result = {"choices":{}};
    const valuesFromOptions = (option) => Array.isArray(option) ? option.map( v => v.value ) : option ? [option.value] : null;
    Object.keys(customization["choices"]).forEach( name => {
      if (!name.endsWith("_invalid")) {
        result["choices"][name] = valuesFromOptions(customization["choices"][name]);
      }
    });
    DEBUG_COMPONENT && console.log(">>>", result);
    return result;
  }

  handleSelectChange(kind, name, selectedOption) {
    DEBUG_COMPONENT && console.log("selectedOption",selectedOption)
    DEBUG_COMPONENT && console.log(kind, name, selectedOption);
    var customization_value = this.state.customization_value;
    if (!customization_value[kind]) {
      customization_value[kind] = [];
    }

    customization_value[kind][name] = selectedOption;

    DEBUG_COMPONENT && console.log("NewState", customization_value)
    this.setState({customization_value}, this.refreshValidity());
  }

  refreshValidity() {
    const { t, config } = this.props;
    const translate = config.translate;
    const lang = ApiV0.getLang();
    const minStr = t('listing.details.min');
    const maxStr = t('listing.details.max');
    var customization_value = this.state.customization_value;
    Object.keys(customization_value).forEach( kind => {
      var configKing = config[kind];

      configKing && configKing.forEach(configEntry => {
        var configName = tr(translate, lang, configEntry.name);
        var min = configEntry.min;
        var max = configEntry.max;
        var selectedOption = customization_value[kind][configName];

        var curSelectedInvalid = "";
        if ( Array.isArray(selectedOption)) {// array constraints : ex. max=2
          if (window.isSet(max) && selectedOption.length > max) {
            curSelectedInvalid = `${max} ${maxStr}`
          } else if (window.isSet(min) && selectedOption.length < min) {
            curSelectedInvalid = `${min} ${minStr}`;
          } else {
            curSelectedInvalid = null;
          }
        } else if (min > 0 && !window.isSet(selectedOption)) {
          curSelectedInvalid = `${min} ${minStr}` ;
        } else {
          curSelectedInvalid = null;
        }
        DEBUG_COMPONENT && console.log(kind, configName+"_invalid", curSelectedInvalid)
        customization_value[kind][configName+"_invalid"] = curSelectedInvalid;
      });

    });

    this.setState({customization_value}, this.sendUpdate());

  }

  sendUpdate() {
    const { onChange } = this.props;
    if (!onChange) {
      console.warn("CListingCustomization: need onChange");
      return;
    }
    const nbOfEntries = this.getNumberOfEntries();
    if (nbOfEntries < 1) {
      onChange(null);
      return;
    }
    const updatedValue = this.getCustomizationValue();
    DEBUG_COMPONENT && console.log("sendUpdate", updatedValue);
    onChange(updatedValue);
  }

  choicesSelectOf(translate, choice, index) {
    const { t } = this.props;
    const minStr = t('listing.details.min');
    const maxStr = t('listing.details.max');
    const noOptionsMessage= unused => t('listing.details.noOptions');
    const lang = ApiV0.getLang();
    const trName = tr(translate, lang, choice.name);
    const options = choice.values.map( v => { return { "label": tr(translate, lang, v), "value": tr(translate, lang, v) }; });
    const min = choice.min ? choice.min : 0;
    const max = choice.max ? choice.max : 1;
    var tooltip = (min > 0 ? `${min} ${minStr}` : '') + (max > 1 ? ` ${max} ${maxStr}` : '');
    if (tooltip !== '') {
      tooltip = ` (${tooltip})`;
    }
    const curSelectedVal = this.state.customization_value["choices"][trName] ? this.state.customization_value["choices"][trName] : '';
    var curSelectedInvalid = this.state.customization_value["choices"][trName+"_invalid"];

    return (<div className="customChoice" key={index}>
       {trName} {tooltip}
       <Select placeholder={t('listing.none')}
               options={options}
               value={curSelectedVal}
               isMulti={max>1}
               isClearable
               isSearchable
               onChange={this.handleSelectChange.bind(this, 'choices', trName)}
               noOptionsMessage={noOptionsMessage}
               />
       { curSelectedInvalid ? (<div className="smallWarn" variant="warning"><span>{curSelectedInvalid}</span></div>) : null}
    </div>
    );
  }

  render() {
    const { t, config } = this.props;
    try {
      // console.log(config);
      const choices = config.choices ? config.choices.map( (c, index) => this.choicesSelectOf(config.translate, c, index)) : null
      return (<>
       <div className="customizeForm">{choices}</div><br/>
       {/* {this.getNumberOfEntries()} Element(s). */}
       {/* <pre>{JSON.stringify(this.state.customization_value)}</pre> */}
      </>);
    } catch (exception) {
      console.error(exception);
      return (<div className="customizeForm">{t('listing.details.productUnavailable')}</div>);
    }
  }
}

export default withTranslation('common')(CListingCustomization);

const tr = (translate, lang, value) => {
  return translate && translate[lang] && translate[lang][value] !== undefined ? translate[lang][value] : value;
}