import React, { Component } from 'react';
import ApiV0 from '../services/ApiV0';
import CCartEntry from './CCartEntry';
import CCartPayment from './CCartPayment';
import CBreadcrumb from '../common/CBreadcrumb';
import CLoadInProgress from '../common/CLoadInProgress';
import './CCart.css';
import { analyticsPageView } from '../core/AnalyticsManager';
import { Alert, Col, Container, Row} from 'react-bootstrap';
import { ImWarning } from 'react-icons/im';

import { withTranslation } from 'react-i18next';

class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      redirectHome: false
    }
  }

  componentDidMount() {
    // DEBUG // console.log("Cart :: componentDidMount", this.props.me.cart)
    const { t } = this.props;
    document.title = t('cart.title');
    analyticsPageView('cart');

    const hasCart = this.isSet(this.props.me) && this.isSet(this.props.me.cart);
    const cart = hasCart ? this.props.me.cart : null;
    const cartCount = hasCart && cart.entries ? cart.entries.length : 0;
    if (cartCount < 1) {
      this.redirectHome();
    }
  }

  redirectHome() {
    window.location.href= '/';
  }

  render() {
    return this.props.me ? this.renderCart() : (<CLoadInProgress/>);
  }

  onCartError(msg) {
    this.setState({errorMessage: msg});
  }

  onUpdated(msg) {
    if (this.props.onUpdated === undefined) {
      console.error("CCart: onUpdated not defined");
      return;
    }
    this.props.onUpdated(msg);
  }

  onCartUpdated(msg) {
    if (this.props.onCartUpdated === undefined) {
      console.error("CCart: onCartUpdated not defined");
      return;
    }
    console.info(`CCart: onCartUpdated : ${msg}`);
    this.props.onCartUpdated(msg);
  }

  cmd() {
    window.location.href= '/command';
  }

  waitReturnHome() {
    window.location.href= '/';
    return (<CLoadInProgress/>);
  }

  renderCart() {
    const cart = this.props.me?.cart;
    const hasCart = this.isSet(cart?.entries) && cart.entries.length > 0;
    if (!hasCart) {
      return this.waitReturnHome();
    }
    const lang = ApiV0.getLang();
    const { t } = this.props;
    const cartMessageRaw = hasCart && this.props.me.cartMessage && this.props.me.cartMessage[lang] !== undefined ? this.props.me.cartMessage[lang]: null;
    const cartMessage = cartMessageRaw !== null && cartMessageRaw !== '' ?
        ( <Alert variant="warning alignCenter"><ImWarning/> <b>{cartMessageRaw}</b></Alert> ) : null ;
    const breadcrumb = (<CBreadcrumb activeLabel={ t('breadcrumb.cart') } />);
    const errorMessage = this.state.errorMessage ?
        ( <Alert variant="warning">{this.state.errorMessage}</Alert> ) : null ;
    const payment = cart.paymentDetails;
    const availableEntries = cart.entries.filter ( e => e.quantity > 0 && e.quantityMax > 0);
    const unavailableEntries = cart.entries.filter ( e => e.quantity < 1 || e.quantityMax < 1);
    const emptyCart = (cart === null || cart === undefined || availableEntries.length < 1)

    const cartEntriesList = !emptyCart ?
        availableEntries.map( (product, index) => {
          return (<CCartEntry key={index}
                              index={index}
                              product={product}
                              onCartUpdated={this.onCartUpdated.bind(this)}
                              onCartError={this.onCartError.bind(this)}
                              mode="cart"
                              />)
        }) : unavailableEntries && unavailableEntries.length > 0 ? null :
      (<div>{t('cart.empty')}</div>);
    const cartPayment = !emptyCart && this.props.me ? (
          <CCartPayment meProfile={this.props.me.profile}
                        payment={payment}
                        onCommand={this.cmd.bind(this)}
                        mode="cart"
                        onUpdated={this.onCartUpdated.bind(this)}/>
      ) : null;
    const cartUnavailableEntriesRow = unavailableEntries && unavailableEntries.length > 0 ? (
      <Row>
       <Col xs={8}>
          <h2>{t('cart.unavailable_entry', {'count': unavailableEntries.length})}</h2>
         { unavailableEntries.map( (product, index) => {
               return (<CCartEntry key={index}
                                   index={index}
                                   product={product}
                                   onCartUpdated={this.onCartUpdated.bind(this)}
                                   onCartError={this.onCartError.bind(this)}
                                   mode="cart"
                                   />)
             })
         }
       </Col>
      </Row>

      ) :null;

    return (<>
      <div className="ccart">
        <div className="cartContent">
          {breadcrumb}
          {errorMessage}
          {cartMessage}
          <Container className="largeScreen">
            <Row style={{ border: "0px solid grey" }}>
             <Col xs={8} style={{ border: "0px solid black" }}>{cartEntriesList}</Col>
             <Col xs={4} style={{ border: "0px solid grey" }}>{cartPayment}</Col>
            </Row>
            {cartUnavailableEntriesRow}
          </Container>
          <Container className="smallScreen">
            <Row style={{ border: "0px solid grey" }}>{cartEntriesList}</Row>
            <Row style={{ border: "0px solid grey" }}>{cartPayment}</Row>
            {cartUnavailableEntriesRow}
          </Container>
        </div>
      </div>
      <div className="clearBoth h20"/>
    </>);
  }

  isSet(variable) {
    return (variable !== undefined && variable !== null);
  }
}

export default withTranslation('common')(Cart);
