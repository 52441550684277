import React, { Component } from 'react';
import './CPassword.css';
import { Navigate } from "react-router-dom";
import ApiV0 from '../services/ApiV0';
import CUtils from '../services/CUtils';
import CBreadcrumb from '../common/CBreadcrumb';
import { CConstants } from '../common/CConstants';
import CFormTip from '../common/CFormTip';
import { analyticsPageView, analyticsEvent } from '../core/AnalyticsManager';
import { Alert } from 'react-bootstrap';
import { FiEyeOff, FiEye } from 'react-icons/fi';

import { withTranslation } from 'react-i18next';

/*
    State : Incoming => PasswordChange
*/
class CPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      state: "Incoming",
      errorMessage: null,
      redirectLogin: false,
      isRevealPwd: false,
      isRevealPwdConfirm: false
    }
  }

  componentDidMount() {
    const { t } = this.props;
    document.title = this.isPasswordChange() ?
        document.title = t('password.title.complete') :
                         t('password.title.verify');
    analyticsPageView(this.isPasswordChange() ? 'password':'verification');
    this._refocus();
    if (!this.isPasswordChange()) {
      this.doVerifyToken(this.props.tokenId);
    }
  }

  _refocus() {
    if (this.passwordInput) {
      this.passwordInput.focus();
    }
  }

  doVerifyToken(token) {
    const { t } = this.props;
    const errorMessage = t('password.invalidToken');
    if (!token) {
      this.setState({errorMessage});
      return;
    }
    ApiV0.verifyToken({token})
    .then(tokenData => {
        // DEBUG // console.info("tokenData", tokenData);
        this.setState({login:tokenData.login, state:'PasswordChange', token});
    })
    .catch(err => {
      console.info(err);
      var evt = "updatePassword TokenInvalid";
      analyticsEvent(CConstants.GG_CATEGORY.AUTH, evt, evt);
      this.setState({errorMessage})
    });
  }

  handleInputChange(event) {
      const target = event.target;
      const value = target.value;
      var name = target.name;
      this.setState({
        [name]: value
      });
  }

  handleSubmit(event) {
      event.preventDefault();
      this.doUpdatePassword(this.state.token, this.state.password, this.state.passwordConfirm);
  }

  doUpdatePassword(token, password, passwordConfirm) {
    const { t } = this.props;
    if (passwordConfirm !== password) {
      this.setState({errorMessage: t('password.invalidConfirm')});
      return;
    }
    ApiV0.updatePassword({token,password})
         .then(updateSuccessData => {
             console.info("updateSuccessData", updateSuccessData);
             var evt = "updatePassword OK";
             analyticsEvent(CConstants.GG_CATEGORY.AUTH, evt, evt);
             this.setState({redirectLogin:true, errorMessage: null}, () => this.props.onJustRegistered());
         })
         .catch(apiError => {
             console.info("updateErrorMessage", apiError);
             const errorMessage = CUtils.userErrorOf("update password", t, apiError);
             const evt = "updatePassword FAILED";
             analyticsEvent(CConstants.GG_CATEGORY.AUTH, evt, evt);
             this.setState({errorMessage})
         });
  }

  isPasswordChange() {
    return (this.state.state === 'PasswordChange');
  }

  render() {
      return this.isPasswordChange() ? this.renderPasswordChange() : this.renderIncoming() ;
  }

  renderIncoming() {
    const { t } = this.props;
    return this.state.errorMessage ? (
                   <div className="tokenError"><Alert variant="warning">
                      {this.state.errorMessage}
                    </Alert></div>
    ) : (<span>{t('password.wip')}</span>);
  }

  toggleEye() {
    this.setState({isRevealPwd:!this.state.isRevealPwd});
  }

  toggleEyeConfirm() {
    this.setState({isRevealPwdConfirm:!this.state.isRevealPwdConfirm});
  }

  renderPasswordChange() {
    const { t } = this.props;
    if (this.passwordInput) {
      this.passwordInput.oninput = function(event) {
         event.target.setCustomValidity('');
      }
      this.passwordInput.oninvalid = function(event) {
         event.target.setCustomValidity(t('password.customValidity'));
      }
    }
    const submitDisabled = !(this.state.password && this.state.passwordConfirm)
    const isRevealPwd = this.state.isRevealPwd;
    const isRevealPwdConfirm = this.state.isRevealPwdConfirm;
    const breadcrumb = (<CBreadcrumb activeLabel={ t('breadcrumb.password') } />);
    return (
        <div className="CPassword">
          {breadcrumb}
          <div className="alignCenter" style={{ maxWidth: "900px" }}>
            { this.state.redirectLogin ? (<Navigate to="/login"/>) : null }
            <form onSubmit={this.handleSubmit.bind(this)} autoComplete="false">
            <div className="card">
              <div className="card-body">

                { this.state.errorMessage ?
                    ( <div  className="passwordError"><Alert variant="warning">
                        {this.state.errorMessage}
                      </Alert></div> )
                    : ( null)
                }

                <div className="form-group">
                    <label>{t('password.form.username.label')}</label>
                    <input name="login"
                           type="text"
                           className="form-control disabled"
                           value={this.state.login}
                           disabled
                            />
                </div>

                <div className="form-group">
                    <label>{t('password.form.password.label')}</label>
                    <input name="password"
                           type={isRevealPwd ? "text" : "password"}
                           autoComplete="new-password"
                           className="form-control"
                           placeholder={t('password.form.password.placeholder')}
                           pattern={CConstants.PASSWORD_REGEX}
                           onInput={e => e.target.setCustomValidity('')}
                           onInvalid={e => e.target.setCustomValidity(t('password.customValidity'))}
                           title=''
                           onChange={this.handleInputChange.bind(this)}
                           ref={(input) => { this.passwordInput = input; }}
                    /><i className="eyeFloatRight"
                         onClick={this.toggleEye.bind(this)}
                         title={isRevealPwd ? t('password.form.password.hide') : t('password.form.password.show')}
                         >{ isRevealPwd ? (<FiEyeOff/>) : (<FiEye/>) }</i>
                    <CFormTip value={this.state.password} minShow={0} max={255}
                              muted={t('password.form.password.muted')}/>
                </div>

                <div className="form-group">
                    <label>{t('password.form.confirm.label')}</label>
                    <input name="passwordConfirm"
                           type={isRevealPwdConfirm ? "text" : "password"}
                           autoComplete="new-password"
                           className="form-control"
                           placeholder={t('password.form.confirm.placeholder')}
                           title=''
                           onChange={this.handleInputChange.bind(this)}
                    /><i className="eyeFloatRight"
                         onClick={this.toggleEyeConfirm.bind(this)}
                         title={isRevealPwdConfirm ? t('password.form.confirm.hide') : t('password.form.confirm.show')}
                         >{ isRevealPwdConfirm ? (<FiEyeOff/>) : (<FiEye/>) }</i>
                </div>

                <button type="submit" className="btn btn-secondary btn-subscribe" disabled={submitDisabled}>{t('password.form.submit')}</button>

              </div>
            </div>
            </form>
          </div>
        </div>
      );
  }
}

export default withTranslation('common')(CPassword);