import React from "react";
import { Form } from 'react-bootstrap';

const CFormTip = (props) => {
  if (props.hide === true) {
    return null;
  }
  return (<div key={props.value}>
      { props.value && props.value.length >= props.minShow ? (
      <div style={{ float: 'right'}}>
        <Form.Text className="text-muted">{props.value.length} / {props.max}</Form.Text>
      </div>
      ):null}
      { props.muted !== undefined ? (<Form.Text className="text-muted">{props.muted}</Form.Text>):null}
  </div>);
}

export default CFormTip;