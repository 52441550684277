import React, { Component } from 'react';
import { HiOutlineClipboardList} from 'react-icons/hi';
import { withTranslation } from 'react-i18next';

import './CListingCommandsAll.css';

class CListingCommandsAll extends Component {
  selectCmd(cmdId) {
    const commands = this.props.commands;
    console.log("select command " + cmdId);
    if (!this.props.onShowCommand) {
      console.warn("CCommandsAll: no onShowCommand");
      return;
    }
    const cmd = commands.find( c => c._id === cmdId);
    this.props.onShowCommand(cmd);
  }

  render() {
    const commands = this.props.commands;
    if (!commands) {
      return (<div></div>);
    }
    const { t } = this.props;
    return (
      <div id="userCommandsAll">
            { commands.map( (cmd, index) => {
                const cmdId = cmd._id;
                return (<CommandEntry t={t} command={cmd} key={index} onClick={this.selectCmd.bind(this, cmdId)} />);
              } ) }
      </div>
    );
  }
}

export default withTranslation('common')(CListingCommandsAll);

function CommandEntry(props) {
   const cmd = props.command;
   return (<div className="cmdEntry" onClick={ props.onClick }><HiOutlineClipboardList/> {cmd.command_id} </div>);
}
