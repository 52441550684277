import queryString from 'query-string';
import FormData from 'form-data';

const JSON_MEDIA = 'application/json';
const JSON_GET = { method: 'GET', headers: { 'Accept': JSON_MEDIA} };
const JSON_HEADERS = { 'Accept': JSON_MEDIA, 'Content-Type': JSON_MEDIA };
const JSON_POST = { method: 'POST', headers: JSON_HEADERS };
const JSON_PUT =  { method: 'PUT', headers: JSON_HEADERS };
const JSON_DELETE = { method: 'DELETE', headers: JSON_HEADERS };

class ApiV0 {
  static debug = false;
  static lang = 'fr';

  static getLang() {
    return ApiV0.lang;
  }

  static setLang(newLang) {
    ApiV0.lang = newLang;
    // DEBUG // console.log("dispatch lang", newLang)
    document.body.dispatchEvent(new CustomEvent("ch-set-lang", {detail: { newLang }}));
  }
  static toggleAdmin() {
    document.body.dispatchEvent(new CustomEvent("ch-toggle-admin"));
  }

  static setAdminMenu(newSection) {
    document.body.dispatchEvent(new CustomEvent("ch-set-admin", {detail: { newSection }}));
  }
  static setAdminAction(action) {
    document.body.dispatchEvent(new CustomEvent("ch-set-admin", {detail: { action }}));
  }

  static setAdminSections(action) {
    document.body.dispatchEvent(new CustomEvent("ch-admin-sections", {detail: { action }}));
  }

  static logout() {
     return ApiV0.simpleFetch("/api/v0/logout");
  }

  static about()  {
     return ApiV0.simpleFetch("/api/v0/about");
  }

  static captcha()  {
     return ApiV0.simpleFetch("/api/v0/captcha");
  }

  static googleSsoUrl() {
     const lang = ApiV0.getLang();
     return `/api/v0/auth/pre-sso?lang=${lang}`;// store creharmony locale into session before redirect to google auth
  }

  static doSend(body) {
    return new Promise((resolve, reject) => {
      fetch('/api/v0/contact', ApiV0._jsonPostBody(body))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  };

  static me()  {
     return ApiV0.simpleFetch('/api/v0/me');
  }

  static updateMe(country) {
    return new Promise((resolve, reject) => {
      fetch('/api/v0/me', ApiV0._jsonPostBody({country}))
      .catch(reject)
      .then(response => ApiV0._response(response, resolve, reject));;
    });
  }

  static updateMeAddress(body) {
    return new Promise((resolve, reject) => {
      fetch('/api/v0/me/address', ApiV0._jsonPostBody(body))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  };

  static removeMeAddress() {
    return new Promise((resolve, reject) => {
      fetch('/api/v0/me/address', ApiV0.enrichWithLang(JSON_DELETE))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  };

  static aliases()  {
     return ApiV0.simpleFetch("/api/v0/countries/aliases");
  }

  static countries()  {
     return ApiV0.simpleFetch("/api/v0/countries");
  }

  static cart()  {
     return ApiV0.simpleFetch("/api/v0/cart");
  }

  static addPromotionCode(body) {
    return new Promise((resolve, reject) => {
      fetch('/api/v0/cart/code', ApiV0._jsonPostBody(body))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  };

  static removePromotionCode() {
    return new Promise((resolve, reject) => {
      fetch('/api/v0/cart/code', JSON_DELETE)
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  };

  static getListings(options) {
    return ApiV0.fetchWithOptions("/api/v0/listings", options);
  }

  static getSections(options) {
    return ApiV0.fetchWithOptions("/api/v0/sections", options);
  }

  static getSection(sectionId) {
    return ApiV0.simpleFetch(`/api/v0/sections/${sectionId}`);
  }

  static listingTags(sectionId, prefix, lang) {
    const queryParams = prefix || lang ? '?'+queryString.stringify({ prefix, lang }) : '';
    return ApiV0.simpleFetch(`/api/v0/tags/listing/${sectionId}${queryParams}`);
  }

  static getListing(listingId, options = null) {
    return ApiV0.fetchWithOptions(`/api/v0/listings/${listingId}`, options);
  }

  static deleteListing(listingId) {
    return new Promise((resolve, reject) => {
      fetch(`/api/v0/admin/listings/${listingId}`, JSON_DELETE)
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  static deleteSection(sectionId) {
    return new Promise((resolve, reject) => {
      fetch(`/api/v0/admin/sections/${sectionId}`, JSON_DELETE)
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  // create CH listing { title, en_title, description, en_description, quantity, weight, shippingType }
  static adminPostListing(body) {
    return new Promise((resolve, reject) => {
      fetch(`/api/v0/admin/listings`, this._jsonPostBody(body))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  // update existing listing { listing_id, title, weight, shippingType }
  static adminPutListing(listingId, body) {
    return new Promise((resolve, reject) => {
      fetch(`/api/v0/admin/listings/${listingId}`, this._jsonPutBody(body))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  static adminAddSection(title) {
    return new Promise((resolve, reject) => {
      fetch(`/api/v0/admin/sections/`, this._jsonPostBody({title}))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  static adminPutSection(shopSectionId, body) {
    return new Promise((resolve, reject) => {
      fetch(`/api/v0/admin/sections/${shopSectionId}`, this._jsonPutBody(body))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  static adminGetUsers(options) {
    return ApiV0.fetchWithOptions("/api/v0/admin/users", options);
  }

  static adminGetUser(userId) {
    return ApiV0.simpleFetch(`/api/v0/admin/users/${userId}`);
  }

  static adminUnlockUser(userId) {
    return new Promise((resolve, reject) => {
      fetch(`/api/v0/admin/users/${userId}/unlock`, ApiV0.enrichWithLang(JSON_POST))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  static adminRemoveUser(userId) {
    return new Promise((resolve, reject) => {
      fetch(`/api/v0/admin/users/${userId}`, ApiV0.enrichWithLang(JSON_DELETE))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  static adminRecoverUser(userId) {
    return new Promise((resolve, reject) => {
      fetch(`/api/v0/admin/users/${userId}/recover`, ApiV0.enrichWithLang(JSON_POST))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  static adminGetCommands(options) {
    return ApiV0.fetchWithOptions("/api/v0/admin/commands", options);
  }

  static adminCancelCommand(commandId) {
    return new Promise((resolve, reject) => {
      fetch(`/api/v0/admin/commands/${commandId}/cancel`, ApiV0.enrichWithLang(JSON_POST))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  static adminValidateCommand(commandId) {
    return new Promise((resolve, reject) => {
      fetch(`/api/v0/admin/commands/${commandId}/validate`, ApiV0.enrichWithLang(JSON_POST))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  static adminSendCommand(commandId, transportName, transportCode, transportLink) {
    const body = {transportName, transportCode, transportLink};
    return new Promise((resolve, reject) => {
      fetch(`/api/v0/admin/commands/${commandId}/send`, ApiV0._jsonPostBody(body))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  static adminFinishCommand(commandId) {
    return new Promise((resolve, reject) => {
      fetch(`/api/v0/admin/commands/${commandId}/finish`, ApiV0.enrichWithLang(JSON_POST))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  static adminCommentCommand(commandId, comment) {
    const body = {comment};
    return new Promise((resolve, reject) => {
      fetch(`/api/v0/admin/commands/${commandId}/comment`, ApiV0._jsonPostBody(body))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  static adminSync(max = 30) {
    return new Promise((resolve, reject) => {
      fetch('/api/v0/admin/sync?max='+max, ApiV0.enrichWithLang(JSON_POST))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  static adminToggleAuditAgent() {
    return new Promise((resolve, reject) => {
      fetch('/api/v0/admin/toggleAuditAgent', ApiV0.enrichWithLang(JSON_POST))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  static adminSyncState() {
    return ApiV0.simpleFetch("/api/v0/admin/sync");
  }

  static adminPostImage(filename, metadata) {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('newImage', filename);
        if (metadata) {
          formData.append('metadata', JSON.stringify(metadata));
        }
        // Object.keys(metadata).forEach( k => formData.append(k, metadata[k]));
        const postOptions = ApiV0.enrichWithLang({
            method: 'POST',
            body: formData
        });
        try {
          fetch('/api/v0/admin/images', postOptions)
          .then(response => ApiV0._response(response, resolve, reject))
          .catch(reject);
        } catch (error) {
          reject(error);
        }
    });
  }

  static adminGetImages(options) {
    return ApiV0.fetchWithOptions("/api/v0/admin/images", options);
  }

  static adminGetImage(imageId, options) {
    return ApiV0.fetchWithOptions("/api/v0/admin/images/"+imageId, options);
  }

  static adminRemoveImage(imageId, listingId = null, force = false) {
    return new Promise((resolve, reject) => {
      const queryParams = force ? '?'+queryString.stringify({"forceDelete":true}) : '';
      const uri = (!window.isSet(listingId)) ?
                        '/api/v0/admin/images/' + imageId + queryParams :
                        '/api/v0/admin/images/' + imageId + '/' + listingId + queryParams;
      fetch(uri, ApiV0.enrichWithLang(JSON_DELETE))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  static adminAddCode(body) {
    return new Promise((resolve, reject) => {
      fetch('/api/v0/admin/codes', this._jsonPostBody(body))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  static adminGetCodes(options) {
    return ApiV0.fetchWithOptions("/api/v0/admin/codes", options);
  }

  static adminRemoveCode(body) {
    return new Promise((resolve, reject) => {
      fetch('/api/v0/admin/codes', this._jsonDeleteBody(body))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  static listPrices() {
    return ApiV0.simpleFetch("/api/v0/shipping");
  }

  static addPrice(body) {
    return new Promise((resolve, reject) => {
      fetch('/api/v0/shipping', ApiV0._jsonPostBody(body))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  static removePrice(body) {
    return new Promise((resolve, reject) => {
      fetch('/api/v0/shipping', this._jsonDeleteBody(body))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  }

  static loadTypes() {
    return ApiV0.simpleFetch("/api/v0/shipping/types");
  }

  static loadWeights() {
    return ApiV0.simpleFetch("/api/v0/shipping/weights");
  }

  static register(body) {
    return new Promise((resolve, reject) => {
      fetch('/api/v0/auth/register', ApiV0._jsonPostBody(body))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  };

  static remindPassword(body) {
    return new Promise((resolve, reject) => {
      fetch('/api/v0/auth/remindPassword', ApiV0._jsonPostBody(body))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  };

  static updatePassword(body) {
    return new Promise((resolve, reject) => {
      fetch('/api/v0/auth/updatePassword', ApiV0._jsonPostBody(body))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  };

  static verifyToken(body) {
    return new Promise((resolve, reject) => {
      fetch('/api/v0/auth/verifyToken', ApiV0._jsonPostBody(body))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  };

  static login(body) {
    return new Promise((resolve, reject) => {
      fetch('/api/v0/auth/login', ApiV0._jsonPostBody(body))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  };

  static isLoginAvailable(login) {
    return new Promise((resolve, reject) => {
      fetch('/api/v0/auth/loginAvailable', ApiV0._jsonPostBody({login}))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  };

  static addToCart(listingId, quantity, variationName, variationValue, customizationValue) {
    var body = {listingId, quantity, variationName, variationValue, customizationValue};
    return new Promise((resolve, reject) => {
      fetch('/api/v0/cart', ApiV0._jsonPostBody(body))
      .then(response => ApiV0._response(response, resolve, reject))
      .catch(reject);
    });
  };

  static fetchWithOptions(apiEndpoint, options) {
     return new Promise((resolve, reject) => {
       const getQueryString = options ? '?'+queryString.stringify(options) : '';
       fetch(`${apiEndpoint}${getQueryString}`, ApiV0.enrichWithLang(JSON_GET))
       .then(response => ApiV0._response(response, resolve, reject))
       .catch(reject);
     });
  }

  static rawFetch(apiEndpoint) {
    return fetch(apiEndpoint, ApiV0.enrichWithLang(JSON_GET));
  }

  static rawFetchPost(apiEndpoint, options) {
    return fetch(apiEndpoint, ApiV0.enrichWithLang(options));
  }

  static simpleFetch(apiEndpoint) {
    return new Promise((resolve, reject) => {
      fetch(apiEndpoint, ApiV0.enrichWithLang(JSON_GET))
      .catch(reject)
      .then(response => ApiV0._response(response, resolve, reject));
    });
  }

  static enrichWithLang(fetchOptions) {
    const apiLang = ApiV0.lang;
    if (!["fr","en"].includes(apiLang)) {
      return;
    }
    if (!('headers' in fetchOptions)) {
      fetchOptions.headers = {};
    }
    fetchOptions.headers["Accept-Language"] = apiLang;
    return fetchOptions;
  }

  static _jsonPostBody(body) {
    var options = ApiV0.enrichWithLang(JSON_POST);
    options.body = JSON.stringify(body);
    return options;
  }

  static _jsonPutBody(body) {
    var options = ApiV0.enrichWithLang(JSON_PUT);
    options.body =JSON.stringify(body);
    return options;
  }

  static _jsonDeleteBody(body) {
    var options = ApiV0.enrichWithLang(JSON_DELETE);
    options.body = JSON.stringify(body);
    return options;
  }

  static _response(response, resolve, reject) {
    ApiV0.consumeResponseBodyAs(response,
      (json) => {
        if (!response.ok && json && json.details && json.id) {
          reject(`${json.details} (id:${json.id})`);
        } else if (!response.ok && json && json.code) {
          reject(json);
        } else if (!response.ok) {// TODO Trad : if with code: rethrow with code and review Cxxx catch
          const result = (json && json.details) ? json.details :
                         (json && json.message) ? json.message : response.status;
          reject(result);
        } else {
          resolve(json);
        }
      },
      (txt) => {
        if (!response.ok) {
          reject(txt);
        } else {
          resolve(txt);// some strange case
        }
      }
    );
  }

  static consumeResponseBodyAs(response, jsonConsumer, txtConsumer) {
    (async () => {
      var responseString = await response.text();
      try{
        if (responseString && typeof responseString === "string"){
         var responseParsed = JSON.parse(responseString);
         if (ApiV0.debug) {
            console.log("RESPONSE(Json)", responseParsed);
         }
         return jsonConsumer(responseParsed);
        }
      } catch(error) {
        // text is not a valid json so we will consume as text
      }
      if (ApiV0.debug) {
        console.log("RESPONSE(Txt)", responseString);
      }
      return txtConsumer(responseString);
    })();
  }
}

export default ApiV0;