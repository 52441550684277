import React, { Component } from 'react'
import './CListingNext.css';
import { withTranslation } from 'react-i18next';
import CListingChoice from '../listings/CListingChoice';

class CListingNext extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    const next = this.props.next;

    if (!next || next.length < 1) {
      return null;
    }

    return (<div className="chNext" itemScope itemType="https://schema.org/ItemList" name={t('listing.next.title')}>
      <div className="listingNextTitle">{t('listing.next.title')}</div>
      <div className="listItems">
        { next.filter( p => p.images && p.images.length > 0 )
              .map(
                (product, index) => (<CListingChoice key={product.listing_id} index={product.listing_id}
                                                     section={this.props.sectionId} product={product}
                                                     origin="next" />)
              )
        }
      </div>
    </div>);
  }
}

export default withTranslation('common')(CListingNext);
