import React from "react";

import { withTranslation } from 'react-i18next';

export default withTranslation('common')((props) => {
   const { value } = props; // t,
   if (value === undefined) {
     return
   }
   const choices = JSON.parse(value).choices;
   if (choices === undefined) {
     return null;
   }
   const choicesKeys = Object.keys(choices);
   const choiceValuesString = (val) => Array.isArray(val) ? val.join(', ') : val;
   return (<>
   {/*<pre>{JSON.stringify(choices)}</pre>*/}
     {choicesKeys.map(
        (k,index) => ( <div key={index}><small>{k}:&#160;{choiceValuesString(choices[k])}</small></div> )
     )}
   </>);
});